import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Header } from "./components/header/Header";
import { LeftMenu } from "./components/leftMenu/LeftMenu";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { loginAction } from "./redux/actions/login.action";
import { CreateNewOrderModalRoute } from "./routes/CreateNewOrderModalRoute/CreateNewOrderModalRoute";
import { DashBoardRoute } from "./routes/Dashboard/DashBoardRoute";
import { IncompletedOrdersRoute } from "./routes/IncompletedOrders/IncompletedOrdersRoute";
import { InternalOrdersRoute } from "./routes/InternalOrders/InternalOrdersRoute";
import { LastSendRoute } from "./routes/LastSend/LastSendRoute";
import { LogInRoute } from "./routes/LogInRoute/LogInRoute";
import { SingleHoseModalRoute } from "./routes/SingleHoseModalRoute/SingleHoseModalRoute";
import { SingleOrderModalRoute } from "./routes/SingleOrderModalRoute/SingleOrderModalRoute";
import { ToSendRoute } from "./routes/ToSend/ToSendRoute";
import { setCustomHeaders, setDefaultBaseUrl } from "./utils/axios.utils";
const apiUrl = process.env.REACT_APP_API_URL;

function App() {
    const dispatch = useAppDispatch();
    let location = useLocation();
    let state = location.state as { backgroundLocation?: Location };
    const { isLogged } = useSelector((state: any) => state.login);
    const xApiKey = window.localStorage.getItem("xApiKey");

    setDefaultBaseUrl(apiUrl);

    if (xApiKey) {
        setCustomHeaders(xApiKey);
        dispatch(loginAction(true));
    }

    return (
        <>
            {xApiKey === "true" || isLogged ? (
                <AppContainer>
                    <Header />
                    <Main>
                        <LeftMenu />

                        <Routes location={state?.backgroundLocation || location}>
                            <Route path="/" element={<Navigate to="/main/dashboard" />} />
                            <Route path="main/dashboard" element={<DashBoardRoute />} />
                            <Route path="main/toSend" element={<ToSendRoute />} />
                            <Route path="main/lastSend" element={<LastSendRoute />} />
                            <Route path="main/incompletedOrders" element={<IncompletedOrdersRoute />} />
                            <Route path="main/internalOrders" element={<InternalOrdersRoute />} />
                        </Routes>

                        {state?.backgroundLocation && (
                            <Routes>
                                <Route path="order/:id" element={<SingleOrderModalRoute />} />
                                <Route path="hose/:hoseId" element={<SingleHoseModalRoute />} />
                                <Route path="order/createNewOrder" element={<CreateNewOrderModalRoute />} />
                            </Routes>
                        )}
                    </Main>
                </AppContainer>
            ) : (
                <LogInRoute />
            )}
        </>
    );
}

export default App;

const Main = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    padding-top: 10px;
`;
const AppContainer = styled.div`
    overflow: scroll;
    background-color: ${({ theme }) => theme.color.app_grey4};
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    box-sizing: border-box;
`;
