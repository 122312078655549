import styled from "styled-components";
import { MenuElement } from "./MenuElement";
import { MenuHeader } from "./MenuHeader";
import { Sync } from "../sync/Sync";

export const LeftMenu = () => {
    return (
        <Wrapper>
            <Wrapper>
                <MenuHeader title="Zamówienia" />
                <MenuElement title="Do realizacji" path="/main/dashboard" />
                <MenuElement title="Do wysyłki" path="/main/toSend" />
                <MenuElement title="Ostatnio zrealizowane" path="/main/lastSend" />
                <div style={{ height: 50 }} />
                <MenuHeader title="Sklep" />
                <MenuElement title="Koszyk info@zakuwanie24.pl" path="/main/internalOrders" />
                <div style={{ height: 50 }} />
                <MenuHeader title="Inne" />
                <MenuElement title="Nieopłacone" path="/main/incompletedOrders" />
            </Wrapper>
            <Sync />
            <p style={{ fontSize: 10, justifySelf: "flex-end" }}>{process.env.REACT_APP_ENVIRONMENT}</p>
            <p style={{ fontSize: 10, justifySelf: "flex-end" }}>{process.env.REACT_APP_API_URL}</p>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    height: 100%;
    min-width: 180px;
    background-color: ${({ theme }) => theme.color.app_grey4};
    display: flex;
    flex-direction: column;
`;
