import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { hosesSlice } from "../redux/slices/hosesSlice";
import { loginSlice } from "../redux/slices/loginSlice";
import { ordersSlice } from "../redux/slices/ordersSlice";
import { syncSlice } from "../redux/slices/syncSlice";
import { userSlice } from "../redux/slices/userSlice";

export const store = configureStore({
    reducer: {
        orders: ordersSlice.reducer,
        hoses: hosesSlice.reducer,
        login: loginSlice.reducer,
        user: userSlice.reducer,
        sync: syncSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ thunk: true }).prepend(logger);
    },
});

export type RootState = ReturnType<typeof store.getState>;
