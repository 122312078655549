import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import { OrdersRow } from "../../components/elements/OrdersRow";
import { ScreenWrapper } from "../../components/wrappers/ScreenWrapper";
import { getOrdersAction } from "../../redux/actions/orders.action";

export const LastSendRoute = () => {
    const dispatch = useDispatch();
    const { orders, loading } = useSelector((state: any) => state.orders);
    const reverseOrders = () => {
        return [...orders].reverse();
    };
    const reversedOrders = reverseOrders();
    useEffect(() => {
        dispatch(getOrdersAction("finished"));
    }, [dispatch]);
    // const [showModal, setShowModal] = React.useState(true);
    // const [password, setPassword] = React.useState("");
    // const checkIfPasswordIsCorrect = () => {
    //     if (password === "Secret00!#") {
    //         setShowModal(false);
    //     }
    // };
    // useEffect(() => {}, []);

    // if (showModal === true) {
    //     return (
    //         <div>
    //             <InputRegular
    //                 type={"password"}
    //                 onTextChange={(e: any) => setPassword(e.target.value)}
    //                 value={password}
    //                 wrapperStyles={{ marginRight: 15 }}
    //                 placeholder={"Wpisz hasło"}
    //             />
    //             <AppButtonRegular title={"Dalej"} onClick={checkIfPasswordIsCorrect} />
    //         </div>
    //     );
    // }
    return (
        <ScreenWrapper>
            <Wrapper>
                <MoonLoader color={"black"} loading={loading === "pending"} size={30} speedMultiplier={0.4} />
                <ListHeader>- Ostatnio zrealizowane</ListHeader>
                <HeaderRow>
                    <RowElement style={{ width: 50 }}>Nr</RowElement>
                    <RowElement>Klient</RowElement>
                    <RowElement>Status</RowElement>
                    <RowElement>Płatność</RowElement>
                    <RowElement>Data</RowElement>
                </HeaderRow>
                <Wrapper style={{ overflow: "scroll", paddingBottom: 100 }}>
                    {reversedOrders?.map((item: any, index: any) => (
                        <OrdersRow key={index} data={item} />
                    ))}
                </Wrapper>
            </Wrapper>
        </ScreenWrapper>
    );
};
const ListHeader = styled.div`
    width: 100%;
    padding: 5px 20px;
    font-size: 16px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.color.app_grey5};
    color: ${({ theme }) => theme.color.white};
    border-bottom: 1px solid ${({ theme }) => theme.color.white};
`;
const RowElement = styled.div`
    width: 20%;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
const HeaderRow = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 10px 0;
    padding-left: 20px;
    text-decoration: none;
    background-color: ${({ theme }) => theme.color.app_lightBlue};
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 0;
`;
