import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { switchOrderStatus, switchPaymentStatusByOrderStatus } from "../../utils/switchOrderStatus";

export const OrdersRow = ({ data }: any) => {
    let location = useLocation();
    return (
        <Row to={`/order/${data?.id}`} state={{ backgroundLocation: location }}>
            <RowElement style={{ width: 50 }}>{data?.id}</RowElement>
            <RowElement>{data?.mail}</RowElement>
            <RowElement>{switchOrderStatus(data?.orderStatus)}</RowElement>
            <RowElement> {switchPaymentStatusByOrderStatus(data?.orderStatus)} </RowElement>
            <RowElement>{data?.orderDate.split(" ")[0]}</RowElement>
        </Row>
    );
};
const RowElement = styled.div`
    width: 20%;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;
const Row = styled(Link)`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 10px 0;
    padding-left: 20px;
    text-decoration: none;
    background-color: ${({ theme }) => theme.color.white};
    border-bottom: 1px solid ${({ theme }) => theme.color.app_lightBlue};
    &:hover {
        background-color: ${({ theme }) => theme.color.app_lightBlue};
    }
`;
