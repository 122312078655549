import { createSlice } from "@reduxjs/toolkit";
import { syncDatabaseAction } from "../actions/sync.action";

interface syncSliceTypes {
    sync: any;
    loading: any;
}

const initialState: syncSliceTypes = {
    sync: null,
    loading: "idle",
};

export const syncSlice = createSlice({
    name: "sync",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(syncDatabaseAction.fulfilled, (state: syncSliceTypes, action) => {
                state.sync = action.payload;
                state.loading = "idle";
            })
            .addCase(syncDatabaseAction.pending, (state: syncSliceTypes, action) => {
                state.sync = action.payload;
                state.loading = "pending";
            })
            .addCase(syncDatabaseAction.rejected, (state: syncSliceTypes, action) => {
                state.sync = action.payload;
                state.loading = "error";
            });
    },
});
