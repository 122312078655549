import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../constants/theme";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
    changeCartItemQuantity,
    getUserDataAction,
    removeHoseAction,
    setCartItemActivity,
} from "../../redux/actions/user.action";
import { USER } from "../../utils/USER.utils";
import { Counter } from "../buttons/Counter";

export const CartHosesRow = ({ item }: any) => {
    const dispatch = useAppDispatch();
    let location = useLocation();

    const changeHoseActivity = () => {
        return dispatch(setCartItemActivity({ newState: !item?.activity, hoseId: item?.ID }));
    };

    const changeHoseQuantity = (type: string, e: any) => {
        e.stopPropagation();
        if (type === "INCREASE") {
            return dispatch(changeCartItemQuantity({ hoseId: item?.ID, hoseQuantity: item?.quantity + 1 }));
        }
        if (type === "DECREASE" && item?.quantity > 1) {
            return dispatch(changeCartItemQuantity({ hoseId: item?.ID, hoseQuantity: item?.quantity - 1 }));
        }
    };
    const deleteHose = () => {
        dispatch(removeHoseAction({ clientId: USER, hoseId: item?.ID })).then(() => dispatch(getUserDataAction(USER)));
    };
    return (
        <Row
            onClick={() => changeHoseActivity()}
            style={{ borderColor: !!item?.activity ? theme.color.app_blue : "transparent" }}>
            <div style={{ width: 50, height: "100%" }}>
                {/* wymien to na checkbox bo sypie blędy */}
                <input type="radio" checked={item?.activity} />
            </div>
            <RowElement style={{ width: 50, fontWeight: "bold" }}>{item?.ID}</RowElement>
            <RowElement style={{ width: 70 }}>{item?.length} cm</RowElement>
            <RowElement>{item?.hoseDetails?.symbol}</RowElement>
            <RowElement>{item?.leftFittingDetails?.symbol}</RowElement>
            <RowElement>{item?.rightFittingDetails?.symbol}</RowElement>
            <RowElement style={{ width: 80 }}>{item?.qrCode}</RowElement>
            <RowElement style={{ width: 80 }}>
                <Counter
                    value={item?.quantity}
                    onIncrement={(e: any) => {
                        changeHoseQuantity("INCREASE", e);
                    }}
                    onDecrement={(e: any) => {
                        changeHoseQuantity("DECREASE", e);
                    }}
                />
            </RowElement>
            <Link to={`/hose/${item?.qrCode}`} state={{ backgroundLocation: location }}>
                <RowElement style={{ width: 120 }}>Podgląd</RowElement>
            </Link>
            <RowElement onClick={() => deleteHose()} style={{ width: 30 }}>
                Usuń
            </RowElement>
        </Row>
    );
};
const RowElement = styled.div`
    width: 18%;
    font-size: 12px;
    font-weight: 500;
`;
const Row = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    padding-left: 20px;
    text-decoration: none;
    border: 2px solid;
    cursor: pointer;
    &:hover {
        background-color: #95c8f820;
    }
`;
