import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { syncDatabaseAction } from "../../redux/actions/sync.action";
import { RootState } from "../../store/store";
import { AppButtonRegular } from "../buttons/AppButtonRegular";

export const Sync = () => {
    const { loading, sync } = useSelector((state: RootState) => state.sync);
    const dispatch = useAppDispatch();
    const handleSync = (type) => {
        dispatch(syncDatabaseAction(type));
    };

    return (
        <div>
            <p style={{ fontSize: 12, fontWeight: 600, marginBottom: 15 }}>Synchronizuje zawsze PROD!!!</p>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}>
                <MoonLoader color={"black"} loading={loading === "pending"} size={20} speedMultiplier={0.4} />
                {loading === "pending" && (
                    <div style={{ fontSize: 10, textAlign: "center" }}>Trwa synchronizacja...</div>
                )}
            </div>
            {sync && <div style={{ fontSize: 10, textAlign: "center" }}>{sync}</div>}
            <AppButtonRegular title={"0 Synchronizuj towar"} onClick={() => handleSync(0)} />
            <AppButtonRegular title={"1 Synchronizuj ZK"} onClick={() => handleSync(1)} />
        </div>
    );
};
