import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BottomWrapper } from "../../components/cartComponents/BottomWrapper";
import { CartHosesRow } from "../../components/elements/CartHosesRow";
import { ScreenWrapper } from "../../components/wrappers/ScreenWrapper";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getUserDataAction } from "../../redux/actions/user.action";
import { RootState } from "../../store/store";
import { USER } from "../../utils/USER.utils";

export const InternalOrdersRoute = () => {
    const dispatch = useAppDispatch();
    const { hosesInCart } = useSelector((state: RootState) => state.user);
    useEffect(() => {
        dispatch(getUserDataAction(USER));
    }, [dispatch]);
    return (
        <ScreenWrapper>
            <Wrapper>
                <ListHeader>- Koszyk info@zakuwanie24.pl</ListHeader>
                <HeaderRow>
                    <div style={{ width: 50, height: "100%" }} />
                    <RowElement style={{ width: 50 }}>Id</RowElement>
                    <RowElement style={{ width: 70 }}>Długość</RowElement>
                    <RowElement>Przewód</RowElement>
                    <RowElement>Lewe Zakucie</RowElement>
                    <RowElement>Prawe Zakucie</RowElement>
                    <RowElement style={{ width: 80 }}>Kod Qr</RowElement>
                    <RowElement style={{ width: 80 }}>Ilość</RowElement>
                    <RowElement style={{ width: 120 }} />
                    <RowElement style={{ width: 30 }} />
                </HeaderRow>
                <Wrapper style={{ overflow: "scroll", paddingBottom: 100 }}>
                    {hosesInCart?.map((item: any, index: number) => (
                        <CartHosesRow item={item} key={index} index={index} />
                    ))}
                </Wrapper>
                <BottomWrapper />
            </Wrapper>
        </ScreenWrapper>
    );
};
const ListHeader = styled.div`
    width: 100%;
    padding: 5px 20px;
    font-size: 16px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.colors.app_red};
    color: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.color.white};
`;
const RowElement = styled.div`
    width: 18%;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
const HeaderRow = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 10px 0;
    padding-left: 20px;
    text-decoration: none;
    background-color: ${({ theme }) => theme.color.app_lightBlue};
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #dd343d25;
    padding: 0;
`;
