import React from "react";
import styled from "styled-components";

export const HoseElement = ({ hoseDetails }: any) => {
    return (
        <div>
            <Title>Przewód</Title>

            <Row>
                <ElementTitle>Nazwa</ElementTitle>
                <ElementValue>{hoseDetails?.nazwa}</ElementValue>
            </Row>
            <Row>
                <ElementTitle>Symbol</ElementTitle>
                <ElementValue>{hoseDetails?.symbol}</ElementValue>
            </Row>
            <Row>
                <ElementTitle>DN</ElementTitle>
                <ElementValue>{hoseDetails?.srednica_wewnetrzna}</ElementValue>
            </Row>
            <Row>
                <ElementTitle>Ciśnienie robocze</ElementTitle>
                <ElementValue>{hoseDetails?.cisnienie_robocze + " bar"}</ElementValue>
            </Row>
        </div>
    );
};

const Title = styled.p`
    margin: 15px 0 5px 0;
    font-size: 16px;
`;
const ElementValue = styled.div`
    padding: 5px;
    width: 300px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 14px;
`;
const ElementTitle = styled.div`
    padding: 5px;
    width: 200px;
    border-right: 1px solid black;
    display: flex;
    align-items: flex-start;
    margin-left: 15px;
    font-size: 14px;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid black;
`;
