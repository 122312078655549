import React from "react";
import styled from "styled-components";

interface Types {
    title: string;
}
export const MenuHeader = ({ title }: Types) => {
    return <Wrapper>{title}</Wrapper>;
};
const Wrapper = styled.div`
    margin: 0;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none !important;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
`;
