//@ts-nocheck
import styled from "styled-components";
const LwymiarFlansza_45 = require("../../assets/hoseLength/L wymiar_FLANSZA_45-1.png");
const LwymiarFlansza_90 = require("../../assets/hoseLength/L wymiar_FLANSZA_90-1.png");
const LwymiarFlansza_prosta = require("../../assets/hoseLength/L wymiar_FLANSZA_prosta-1.png");
const LwymiarGW_45 = require("../../assets/hoseLength/L wymiar_GW_45-1.png");
const LwymiarGW_90 = require("../../assets/hoseLength/L wymiar_GW_90-1.png");
const LwymiarGW_prosta = require("../../assets/hoseLength/L wymiar_GW_prosta-1.png");
const LwymiarGZ_prosta = require("../../assets/hoseLength/L wymiar_GZ_prosta-1.png");
const LwymiarOczko = require("../../assets/hoseLength/L wymiar_OCZKO-1.png");
const LwymiarNietypowe = require("../../assets/hoseLength/L wymiar_NIETYPOWE-1.png");
const PwymiarFlansza_45 = require("../../assets/hoseLength/P wymiar_FLANSZA_45-1.png");
const PwymiarFlansza_90 = require("../../assets/hoseLength/P wymiar_FLANSZA_90-1.png");
const PwymiarFlansza_prosta = require("../../assets/hoseLength/P wymiar_FLANSZA_prosta-1.png");
const PwymiarGW_45 = require("../../assets/hoseLength/P wymiar_GW_45-1.png");
const PwymiarGW_90 = require("../../assets/hoseLength/P wymiar_GW_90-1.png");
const PwymiarGW_prosta = require("../../assets/hoseLength/P wymiar_GW_prosta-1.png");
const PwymiarGZ_prosta = require("../../assets/hoseLength/P wymiar_GZ_prosta-1.png");
const PwymiarOczko = require("../../assets/hoseLength/P wymiar_OCZKO-1.png");
const PwymiarNietypowe = require("../../assets/hoseLength/P wymiar_NIETYPOWE-1.png");

export const HoseImage = ({ leftFitting, rightFitting }: any) => {
    const setLeftImage = () => {
        switch (leftFitting?.typ_zakucia) {
            case "wewnetrzne":
                if (leftFitting.kat_zakucia === "proste") {
                    return <Image src={LwymiarGW_prosta} alt="lengthExample" />;
                } else if (leftFitting.kat_zakucia === "90 stopni") {
                    return <Image src={LwymiarGW_90} alt="lengthExample" />;
                } else {
                    return <Image src={LwymiarGW_45} alt="lengthExample" />;
                }
            case "zewnetrzne":
                return <Image src={LwymiarGZ_prosta} alt="lengthExample" />;
            case "oczko":
                return <Image src={LwymiarOczko} alt="lengthExample" />;
            case "flansza":
                if (leftFitting.kat_zakucia === "proste") {
                    return <Image src={PwymiarFlansza_prosta} alt="left_flansza_prosta" />;
                } else if (leftFitting.kat_zakucia === "90 stopni") {
                    return <Image src={LwymiarFlansza_90} alt="lengthExample" />;
                } else {
                    return <Image src={LwymiarFlansza_45} alt="lengthExample" />;
                }
            case "nietypowe":
                return <Image src={LwymiarNietypowe} alt="lengthExample" />;
        }
    };
    const setRightImage = () => {
        switch (rightFitting?.typ_zakucia) {
            case "wewnetrzne":
                if (rightFitting.kat_zakucia === "proste") {
                    return <Image src={PwymiarGW_prosta} alt="lengthExample" />;
                } else if (rightFitting.kat_zakucia === "90 stopni") {
                    return <Image src={PwymiarGW_90} alt="lengthExample" />;
                } else {
                    return <Image src={PwymiarGW_45} alt="lengthExample" />;
                }
            case "zewnetrzne":
                return <Image src={PwymiarGZ_prosta} alt="lengthExample" />;
            case "oczko":
                return <Image src={PwymiarOczko} alt="lengthExample" />;
            case "flansza":
                if (rightFitting.kat_zakucia === "proste") {
                    return <Image src={LwymiarFlansza_prosta} alt="right_flansza_prosta" />;
                } else if (rightFitting.kat_zakucia === "90 stopni") {
                    return <Image src={PwymiarFlansza_90} alt="lengthExample" />;
                } else {
                    return <Image src={PwymiarFlansza_45} alt="lengthExample" />;
                }
            case "nietypowe":
                return <Image src={PwymiarNietypowe} alt="lengthExample" />;
        }
    };
    return (
        <ImageWrap>
            {setLeftImage()}
            {setRightImage()}
        </ImageWrap>
    );
};

const ImageWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.app_lightGrey};
    height: 150px;
    width: 100%;
    max-width: 500px;
    overflow: hidden;
    margin-bottom: 8px;
`;
const Image = styled.img`
    object-fit: contain;
    height: 124px;
    width: 45%;
    cursor: pointer;
`;
