import axios from "axios";

export const getUserDataService = (userId: string | number) => {
    return axios.get(`/get-user-info.php?id=${userId}`);
};
export const removeHoseByIdService = ({ clientId, hoseId }: any) => {
    return axios.get(`/remove-hose.php?client-id=${clientId}&hose-id=${hoseId}`);
};
export const createOrderService = (orderData: any) => {
    return axios.post(
        `order.php`,
        `clientId=${orderData.clientId}
        &clientData=${orderData.clientData}
        &courierInfo=${orderData.courierInfo}
        &invoiceData=${orderData.invoiceData}
        &price=${orderData.price}
        &payment=${orderData.payment}
        &shipping=${orderData.shipping}
        &sendData=${orderData.sendData}
        &hosesIds=${orderData.hosesIds}
        &oldClient=${orderData.oldClient}`
    );
};