import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppButtonRegular } from "../../components/buttons/AppButtonRegular";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useCartTotalPrice } from "../../hooks/useCartTotalPrice";
import { createOrderAction, getUserDataAction } from "../../redux/actions/user.action";
import { RootState } from "../../store/store";
import { USER } from "../../utils/USER.utils";

export const CreateNewOrderModalRoute = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { hosesInCart, searchedClient } = useSelector((state: RootState) => state.user);
    const { cartTotalPrice } = useCartTotalPrice(hosesInCart);

    const listOfHosesIdsWithQuantity = hosesInCart
        .filter((item: any) => item.activity)
        .map((item: any) => ({ id: item.ID, quantity: item.quantity }));
    const listOfHosesToOrder = hosesInCart.filter((item: any) => item.activity);
    const OrderData = {
        clientData: JSON.stringify({
            companyData: searchedClient?.companyData ? searchedClient?.companyData : "",
            individualData: searchedClient?.individualData ? searchedClient?.individualData : "",
            id: searchedClient?.id,
            email: searchedClient?.email ? searchedClient?.email : "randomemail@email.com",
            phone: searchedClient?.nip ? searchedClient?.phone : "123456789",
        }),
        clientId: searchedClient?.id,
        courierInfo: " ",
        hosesIds: JSON.stringify(listOfHosesIdsWithQuantity),
        invoiceData: JSON.stringify({
            nip: "8170001112",
            companyName: "company random    ",
            address1: "adres 1",
            address2: "adres 2",
        }),
        payment: "payU",
        price: cartTotalPrice,
        sendData: JSON.stringify({
            fname: "ZK",
            lname: "Zakuwanie24",
            address1: "adress111",
            address2: "adress222",
            mail: searchedClient?.email ? searchedClient?.email : "",
            phone: searchedClient?.nip ? searchedClient?.phone : "",
        }),
        shipping: 0,
        oldClient: USER,
    };

    const createNewOrder = () => {
        dispatch(createOrderAction(OrderData))
            .then(() => navigate(-1))
            .then(() => dispatch(getUserDataAction(USER)));
    };
    return (
        <Background onClick={() => navigate(-1)}>
            <Wrapper onClick={(e) => e.stopPropagation()}>
                <Title>Podsumowanie zamówienia</Title>
                <Text style={{ marginBottom: 15 }}>
                    Upewnij się, że wszystkie przewody są w zamówieniu, oraz sprawdź, czy wybrany jest odpowiedni
                    klient. Zamówienie otrzyma status “Do realizacji”.
                </Text>
                {listOfHosesToOrder.map((item: any) => (
                    <ResultWrap>
                        <ResultElement>{item?.length} cm</ResultElement>
                        <ResultElement>{item?.hoseDetails?.symbol}</ResultElement>
                        <ResultElement>{item?.leftFittingDetails?.symbol}</ResultElement>
                        <ResultElement>{item?.rightFittingDetails?.symbol}</ResultElement>
                        <ResultElement>{item?.qrCode}</ResultElement>
                        <ResultElement>{item?.quantity} szt.</ResultElement>
                    </ResultWrap>
                ))}
                <BottomWrap>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <ResultElement>email: {searchedClient?.email}</ResultElement>
                        <ResultElement>NIP: {searchedClient?.nip}</ResultElement>
                        <ResultElement>tel: {searchedClient?.phone}</ResultElement>
                        <ResultElement>id: {searchedClient?.id}</ResultElement>
                    </div>
                    <div>
                        <ResultElement style={{ fontWeight: "700", marginBottom: 5 }}>
                            Do zapłaty: {(cartTotalPrice / 100).toFixed(2)} zł
                        </ResultElement>

                        <AppButtonRegular
                            styles={{ padding: 10, paddingTop: 5, paddingBottom: 5 }}
                            title="Utwórz zamówienie"
                            onClick={createNewOrder}
                        />
                    </div>
                </BottomWrap>
            </Wrapper>
        </Background>
    );
};
const ResultWrap = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${({ theme }) => theme.color.app_lightBlue};
    height: 24px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    width: 100%;
`;

const ResultElement = styled.p`
    font-size: 11px;
    font-weight: 600;
    margin-right: 10px;
`;
const BottomWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
    border-top: 1px solid grey;
    padding-top: 10px;
`;
const Text = styled.div`
    font-weight: 500;
    font-size: 11px;
`;
const Title = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
`;
const Wrapper = styled.div`
    position: relative;
    padding: 30px;
    padding-bottom: 15px;
    margin-top: 50px;
    width: 80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
    box-shadow: 0px 20px 75px rgba(0, 0, 0, 0.13);
`;
const Background = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: rgba(107, 112, 121, 0.3);
    backdrop-filter: blur(2px);
    transition: background-color 400ms ease-in-out;
    top: 0;
    left: 0;
    position: fixed;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
