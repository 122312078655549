import { useNavigate } from "react-router-dom";
import { changeOrderStatusAction, getOrdersAction } from "../redux/actions/orders.action";
import { useAppDispatch } from "./useAppDispatch";

interface types {
    status: string;
    orderId: string | undefined;
    route: string;
}
export const useOrderStatus = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const changeStatus = async ({ status, orderId, route }: types) =>
        dispatch(changeOrderStatusAction({ id: orderId, status: status })).then(() => {
            dispatch(getOrdersAction(status));
            navigate(route);
        });

    return { changeStatus };
};
