import axios from "axios";

export const fetchOrdersService = async (status: any) => {
    return await axios.get(`/orders-by-status.php?status=${status}`);
};
export const fetchSingleOrderService = async (id: any) => {
    return await axios.get(`/get-order.php?id=${id}`);
};
export const changeOrderStatusService = async ({ id, status }: any) => {
    return await axios.get(`/order-status.php?id=${id}&status=${status}`);
};
