import { createSlice } from "@reduxjs/toolkit";
import { getHoseByIdAction } from "../actions/hoses.action";

interface hosesTypes {
    loading: any;
    singleHose: any;
}

const initialState: hosesTypes = {
    loading: "idle",
    singleHose: undefined,
};

export const hosesSlice = createSlice({
    name: "hoses",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHoseByIdAction.fulfilled, (state: hosesTypes, action: any) => {
                state.singleHose = action.payload;
                state.loading = "idle";
            })
            .addCase(getHoseByIdAction.pending, (state: hosesTypes) => {
                state.loading = "pending";
            })
            .addCase(getHoseByIdAction.rejected, (state: hosesTypes) => {
                state.loading = "error";
            });
    },
});
