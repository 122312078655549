import axios from "axios";

export const setDefaultBaseUrl = (baseURL: string) => {
    axios.defaults.baseURL = baseURL;
};
export const setCustomHeaders = (xApiKey) => {
    const apiToken = process.env.REACT_APP_MY_API_KEY;
    //@ts-ignore
    axios.defaults.headers.common["api-token"] = apiToken;
    //@ts-ignore
    axios.defaults.headers.common["x-api-key"] = xApiKey;
};
