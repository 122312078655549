import React from "react";
import styled from "styled-components";

export const ScreenWrapper = ({ children }: any) => {
    return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
    background-color: white;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;
