import { createSlice } from "@reduxjs/toolkit";
import {
    changeCartItemQuantity,
    createOrderAction,
    getUserDataAction,
    removeHoseAction,
    searchUserAction,
    setCartItemActivity,
} from "../actions/user.action";

interface userTypes {
    loading: any;
    hosesInCart: any;
    searchedClient: any;
}

const initialState: userTypes = {
    loading: "idle",
    hosesInCart: [],
    searchedClient: null,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserDataAction.fulfilled, (state: userTypes, action: any) => {
                state.hosesInCart = action.payload;
                state.loading = "idle";
            })
            .addCase(getUserDataAction.pending, (state: userTypes) => {
                state.loading = "pending";
            })
            .addCase(getUserDataAction.rejected, (state: userTypes) => {
                state.loading = "error";
            })
            .addCase(searchUserAction.fulfilled, (state: userTypes, action: any) => {
                const clientData = action.payload[0];
                state.searchedClient = {
                    id: clientData?.id,
                    email: clientData?.mail,
                    phone: clientData?.phone,
                    nip: clientData?.companyData?.nip,
                    individualData: clientData?.individualData,
                    companyData: clientData?.companyData,
                };
                state.loading = "idle";
            })
            .addCase(searchUserAction.pending, (state: userTypes) => {
                state.loading = "pending";
            })
            .addCase(searchUserAction.rejected, (state: userTypes) => {
                state.loading = "error";
            })
            .addCase(changeCartItemQuantity, (state, action) => {
                const updatedHosesList = state?.hosesInCart?.map((item: any) => {
                    if (item.ID === action?.payload.hoseId) {
                        item.quantity = action?.payload.hoseQuantity;
                    }
                    return item;
                });
                state.hosesInCart = updatedHosesList;
            })
            .addCase(setCartItemActivity, (state, action) => {
                const updatedHosesList = state?.hosesInCart?.map((item: any) => {
                    if (item.ID === action?.payload.hoseId) {
                        item.activity = action?.payload.newState;
                    }
                    return item;
                });
                state.hosesInCart = updatedHosesList;
            })
            .addCase(createOrderAction.fulfilled, (state: userTypes) => {
                state.loading = "idle";
            })
            .addCase(createOrderAction.pending, (state: userTypes) => {
                state.loading = "pending";
            })
            .addCase(createOrderAction.rejected, (state: userTypes) => {
                state.loading = "error";
            })
            .addCase(removeHoseAction.fulfilled, (state: userTypes) => {
                state.loading = "idle";
            })
            .addCase(removeHoseAction.pending, (state: userTypes) => {
                state.loading = "pending";
            })
            .addCase(removeHoseAction.rejected, (state: userTypes) => {
                state.loading = "error";
            });
    },
});
