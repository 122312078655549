import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../constants/theme";

interface Types {
    path: string;
    title: string;
    userId?: string | null;
}
export const MenuElement = ({ path, title, userId = null }: Types) => {
    let location = useLocation();

    return (
        <Link
            style={{
                textDecoration: "none",
                color: "black",
            }}
            to={path}
            state={{ userId: userId }}>
            <MenuWrapper
                style={{
                    fontWeight: location.pathname === path ? "700" : "600",
                    backgroundColor: location.pathname === path ? theme.color.white : "transparent",
                }}>
                {location.pathname === path && "-- "}
                {title}
            </MenuWrapper>
        </Link>
    );
};

const MenuWrapper = styled.div`
    margin: 0;
    padding: 10px 30px;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none !important;
    border-bottom: 1px solid #e0e0e0;
`;
