import { useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { loginAction } from "../../redux/actions/login.action";

export const LogInRoute = () => {
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async (event: any) => {
        event.preventDefault();
        try {
            const response = await fetch(
                "https://panel-zakuwanie24-staging.netlify.app/.netlify/functions/password-verification",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ username, password }),
                }
            );
            const data = await response.json();
            if (data.xApiKey) {
                dispatch(loginAction(true));
                localStorage.setItem("xApiKey", data.xApiKey);
            }
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <Wrapper onSubmit={handleLogin}>
            <Wrap>
                <div>
                    <label htmlFor="username">Username:</label>
                    <Input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="password">Password:</label>
                    <Input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <button onClick={handleLogin}>Log in</button>
            </Wrap>
        </Wrapper>
    );
};

const Input = styled.input`
    width: 200px;
    height: 40px;
    margin: 5px;
`;
const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Wrapper = styled.form`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`;
