import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useCartTotalPrice } from "../../hooks/useCartTotalPrice";
import { searchUserAction } from "../../redux/actions/user.action";
import { RootState } from "../../store/store";
import { AppButtonRegular } from "../buttons/AppButtonRegular";
import InputRegular from "../inputs/InputRegular";
import { theme } from "../../constants/theme";

const options = ["EMAIL", "NIP", "PHONE"];

export const BottomWrapper = () => {
    const navigate = useNavigate();
    let location = useLocation();
    const dispatch = useAppDispatch();
    const [searchType, setSearchType] = useState("EMAIL");
    const [searchString, setSearchString] = useState("");
    const { searchedClient, hosesInCart, loading } = useSelector((state: RootState) => state.user);
    const { cartTotalPrice } = useCartTotalPrice(hosesInCart);

    const searchUser = () => {
        dispatch(searchUserAction({ type: searchType, data: searchString }));
    };

    const createNewOrder = () => {
        if (searchedClient?.id === null) {
            return alert("Nie wybrano klienta");
        } else if (hosesInCart.length === 0) {
            return alert("Nie wybrano żadnych przewodów");
        } else {
            return navigate("/order/createNewOrder", { state: { backgroundLocation: location } });
        }
    };
    const loadSelfUserData = () => {
        dispatch(searchUserAction({ type: "EMAIL", data: "weze@zakuwanie24.pl" }));
        setSearchString("weze@zakuwanie24.pl");
        setSearchType("EMAIL");
    };
    return (
        <Wrapper>
            <LeftWrap>
                <Title>
                    Wybierz klienta któremu przypiszesz zlecenie, lub przypisz na{" "}
                    <span style={{ cursor: "pointer", color: theme.colors.app_blue }} onClick={loadSelfUserData}>
                        weze@zakuwanie24.pl
                    </span>
                </Title>

                <SearchWrap>
                    <SearchTypeSelect onChange={(e) => setSearchType(e.target.value)}>
                        {options.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </SearchTypeSelect>

                    <InputRegular
                        onTextChange={(e: any) => setSearchString(e.target.value)}
                        value={searchString}
                        wrapperStyles={{ marginRight: 15 }}
                        placeholder={searchType}
                    />
                    <MoonLoader color={"black"} loading={loading === "pending"} size={20} speedMultiplier={0.4} />

                    <AppButtonRegular onClick={() => searchUser()} title="Wyszukaj" styles={{ height: 32 }} />
                </SearchWrap>
                <ResultWrap>
                    <ResultElement>email: {searchedClient?.email}</ResultElement>
                    <ResultElement>NIP: {searchedClient?.nip}</ResultElement>
                    <ResultElement>tel: {searchedClient?.phone}</ResultElement>
                    <ResultElement>id: {searchedClient?.id}</ResultElement>
                </ResultWrap>
            </LeftWrap>
            <RightWrap>
                <Title>Suma: {(cartTotalPrice / 100).toFixed(2)} zł</Title>
                <AppButtonRegular onClick={createNewOrder} title="Utwórz zamówienie" />
            </RightWrap>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 200px;
    background-color: white;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
    padding: 10px 15px;
`;
const SearchWrap = styled.div`
    display: flex;
    flex-direction: row;
`;
const ResultWrap = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${({ theme }) => theme.color.app_lightBlue};
    height: 24px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-top: 15px;
`;

const ResultElement = styled.p`
    font-size: 12px;
    font-weight: 600;
    margin-right: 25px;
`;
const LeftWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-right: 15px;
`;
const RightWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 20%;
    padding-left: 15px;
    border-left: 1px solid grey;
    height: 100%;
`;
const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
`;
const SearchTypeSelect = styled.select`
    margin-right: 20px;
    height: 32px;
    border: none;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
    background-color: ${({ theme }) => theme.color.app_lightBlue};
`;
