import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { createOrderService, getUserDataService, removeHoseByIdService } from "../../services/user.service";
import { countSingleHosePrice } from "../../utils/countSingleHosePrice";
import { parsePrice } from "../../utils/parsePrice";

export const changeCartItemQuantity: any = createAction("user/hose/changeQuantity");
export const setCartItemActivity: any = createAction("user/hose/changeAvailability");

export const getUserDataAction: any = createAsyncThunk("user/getUserData", async (id: string) => {
    const response = await getUserDataService(id);
    const hosesInCart = response.data.slice(1, -1).map((item: any) => {
        const singleHosePriceSum = countSingleHosePrice({
            length: item.length,
            hosePrice: parsePrice(item?.hoseDetails?.cena),
            leftFittingPrice: parsePrice(item?.leftFittingDetails?.cena),
            rightFittingPrice: parsePrice(item?.rightFittingDetails?.cena),
            sleevePrice: parsePrice(item?.sleeveDetails?.cena),
        });
        return { ...item, totalPrice: singleHosePriceSum, activity: true, quantity: 1 };
    });
    return hosesInCart;
});
export const searchUserAction: any = createAsyncThunk("user/searchUser", async ({ type, data }: any) => {
    if (type === "EMAIL") {
        const response = await axios.get(`/get-user-info.php?mail=${data}`);
        return response.data;
    }
    if (type === "PHONE") {
        const response = await axios.get(`/get-user-info.php?phone=${data}`);
        return response.data;
    }
    if (type === "NIP") {
        const response = await axios.get(`/get-user-info.php?nip=${data}`);
        return response.data;
    }
});
export const createOrderAction: any = createAsyncThunk("orders/createOrder", async (orderData: any) => {
    const response = await createOrderService(orderData)
    return response.data
})
export const removeHoseAction: any = createAsyncThunk("orders/removeHose", async ({ clientId, hoseId }: any) => {
    const response = await removeHoseByIdService({ clientId, hoseId })
    return response.data
})
