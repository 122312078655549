import { useDispatch } from "react-redux";
import styled from "styled-components";
import { loginAction } from "../../redux/actions/login.action";
import { theme } from "../../constants/theme";
const LogoColor = require("../../assets/logo-color.png");

const app_env = process.env.REACT_APP_ENVIRONMENT;

export const Header = () => {
    const dispatch = useDispatch();
    const handleLogOut = () => {
        localStorage.removeItem("xApiKey");
        dispatch(loginAction(false));
    };
    return (
        <Wrapper
            style={{
                backgroundColor: app_env === "production" ? theme.color.app_lightBlue : theme.color.app_orange,
            }}>
            <div>
                <Logo src={LogoColor} alt="lengthExample" />
                <Title>Panel Zarządzania</Title>
            </div>
            <Text onClick={handleLogOut}>Wyloguj</Text>
        </Wrapper>
    );
};

const Logo = styled.img`
    object-fit: contain;
    height: 20px;
    cursor: pointer;
`;
const Text = styled.p`
    color: ${({ theme }) => theme.color.black};
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
        font-weight: 800;
        color: black;
    }
`;
const Title = styled.p`
    color: ${({ theme }) => theme.color.black};
    font-size: 12px;
    font-weight: 600;
    margin-left: 30px;
    margin-top: -8px;
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    background-color: ${({ theme }) => theme.color.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 50px 10px 20px;
    -webkit-box-shadow: 0px 20px 21px -20px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 20px 21px -20px rgba(66, 68, 90, 1);
    box-shadow: 0px 20px 21px -20px rgba(66, 68, 90, 1);
`;
