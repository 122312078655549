import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

export const HosesRow = ({ item }: any) => {
    let location = useLocation();

    // item?.quantity;

    const hoseDetails = item?.hose_details[0]?.hoseDetails;
    const leftFittingDetails = item?.hose_details[0]?.leftFittingDetails;
    const rightFittingDetails = item?.hose_details[0]?.rightFittingDetails;
    const coverDetails = item?.hose_details[0]?.coverDetails;
    return (
        <Row
            to={`/hose/${item?.hose_details[0]?.qrCode}`}
            state={{ backgroundLocation: location, quantity: item?.quantity }}>
            <RowElement style={{ width: 40 }}>{item?.id}</RowElement>
            <RowElement>{hoseDetails?.symbol}</RowElement>
            <RowElement>{leftFittingDetails?.symbol}</RowElement>
            <RowElement>{rightFittingDetails?.symbol}</RowElement>
            <RowElement>{coverDetails ? "TAK" : "NIE"}</RowElement>
            <RowElement>{item?.hose_details[0]?.qrCode}</RowElement>
            <RowElement>{item?.hose_details[0]?.status}</RowElement>
            <RowElement style={{ width: 40, fontWeight: "bold" }}>{item?.quantity}</RowElement>
        </Row>
    );
};
const RowElement = styled.div`
    width: 20%;
    padding-left: 10px;
`;
const Row = styled(Link)`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 95%;
    padding: 15px 5px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        background-color: #95c8f820;
    }
`;
