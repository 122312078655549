import React from "react";
import styled from "styled-components";

export const Counter = ({ value, onIncrement, onDecrement }: any) => {
    return (
        <Wrapper>
            <Display>{value}</Display>
            <BtnsWrapper>
                <Up onClick={onIncrement}>+</Up>
                <Down onClick={onDecrement}>-</Down>
            </BtnsWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    overflow: wrap;
    border: 1px solid blue;
    height: 100%;
    background-color: white;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.color.app_grey6};
    width: 40px;
    padding: 0 5px;
`;
const BtnsWrapper = styled.div`
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Up = styled.div`
    height: 11px;
    width: 11px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: none !important;
    background-color: white !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:focus {
        outline: none !important;
    }
`;
const Down = styled.div`
    height: 11px;
    width: 11px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: none !important;
    background-color: white !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:focus {
        outline: none !important;
    }
`;
const Display = styled.div`
    padding-right: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;
