import React from "react";
import styled from "styled-components";

interface Types {
    title: string;
    onClick: any;
    color?: any;
    bgColor?: any;
    borderColor?: any;
    disable?: boolean;
    styles?: any;
}
export const AppButtonRegular = ({
    title,
    onClick,
    color = "white",
    bgColor = "#2699FB",
    borderColor = "transparent",
    disable = false,
    styles,
}: Types) => {
    return (
        <Wrap
            style={{ backgroundColor: bgColor, borderColor: borderColor, ...styles }}
            onClick={disable ? undefined : onClick}>
            <Title style={{ color: color }}>{title}</Title>
        </Wrap>
    );
};

const Title = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.color.white};
    font-size: 12px;
    font-weight: 700;
`;
const Wrap = styled.div`
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid;
    cursor: pointer;
`;
