import { createAsyncThunk } from "@reduxjs/toolkit";
import { changeOrderStatusService, fetchOrdersService, fetchSingleOrderService } from "../../services/orders.service";

export const getOrdersAction: any = createAsyncThunk("orders/getOrders", async (status: string) => {
    const response = await fetchOrdersService(status);
    return response.data;
});
export const getSingleOrderAction: any = createAsyncThunk("orders/getSingleOrder", async (id: string) => {
    const response = await fetchSingleOrderService(id);
    return response.data;
});
export const changeOrderStatusAction: any = createAsyncThunk(
    "orders/changeOrderStatus",
    async ({ id, status }: any) => {
        const response = await changeOrderStatusService({ id, status });
        return response.data;
    }
);
