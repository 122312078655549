import { createSlice } from "@reduxjs/toolkit";
import { loginAction } from "../actions/login.action";

interface loginTypes {
    isLogged: boolean
}

const initialState: loginTypes = {
    isLogged: false
};

export const loginSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginAction, (state: loginTypes, action) => {
                state.isLogged = action.payload;
            });
    },
});
